* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
}

html {
  height: 100%;
}

body {
  background: url('./assets/background.png') no-repeat center left;
  background-size: cover;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body:after {
    content: '';
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    left: 0;
    right: 0;
    z-index: -1; /* needed to keep in the background */
    background: url('./assets/background@2x.png') left -150px top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  header {
    padding: 5vw;
  }

  header h2 {
    font-size: 8vw;
    font-weight: 400;
  }

  header p {
    font-size: 5vw;
  }

  header .logo-wrapper {
    max-width: 200px;
  }

  header .text-wrapper {
    padding: 5vw 0 0;
  }

  footer {
    padding: 5vw;
    font-size: 6vw;
    color: #000;
    text-align: center;
  }

  .image-container {
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 5vw;
    position: relative;
  }

  .holder {
    padding: 156.25% 0 0 0;
  }

  .image-container .video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .image-container .video-hidden {
    opacity: 0.2;
  }

  .image-container .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3vw;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .image-container .image {
    flex: 0 0 32%;
    height: 15.6%;
    overflow: hidden;
  }

  .image-container .blocked {
    cursor: default;
    opacity: 0.7;
  }

  .image-container .image .front {
    cursor: pointer;
  }

  .image-container .blocked .front {
    cursor: default;
  }

  .image-container .image .back {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .image-container .image .back img {
    width: 70%;
  }

  .image-container .image.is-opened .back {
    animation: zoom-out 0.6s 0.3s;
    animation-fill-mode: forwards;
  }

  .react-card-flip-container {
    width: 100%;
    height: 100%;
  }

  .control-button {
    appearance: none;
    border: 0;
    padding: 2vw 7vw;

    color: #fff;
    font-size: 7vw;
    text-transform: uppercase;
    background-color: #e80909;
    border-radius: 5px;
    cursor: pointer;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  body:after {
    content: '';
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    left: 0;
    right: 0;
    z-index: -1; /* needed to keep in the background */
    background: url('./assets/background@2x.png') left -210px top no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  header {
    padding: 3vw;
  }

  header h2 {
    font-size: 7vw;
    font-weight: 400;
  }

  header p {
    font-size: 4vw;
  }

  header .logo-wrapper {
    max-width: 240px;
  }

  header .text-wrapper {
    padding: 3vw 0 0;
    text-align: center;
  }

  footer {
    padding: 3vw;
    font-size: 6vw;
    color: #000;
    text-align: center;
  }

  .image-container {
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 20vw;
    position: relative;
  }

  .holder {
    padding: 180.25% 0 0 0;
  }

  .image-container .video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .image-container .video-hidden {
    opacity: 0.2;
  }

  .image-container .bird {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .image-container .bird.bird-hidden:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .image-container .bird.bird-fadeout {
    animation: fade-out 0.6s 0.3s;
    animation-fill-mode: forwards;
  }

  .image-container .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3vw;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .image-container .image {
    flex: 0 0 32%;
    height: 15.6%;
    overflow: hidden;
  }

  .image-container .blocked {
    cursor: default;
    opacity: 0.7;
  }

  .image-container .image .front {
    cursor: pointer;
  }

  .image-container .blocked .front {
    cursor: default;
  }

  .image-container .image .back {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .image-container .image .back img {
    width: 90%;
  }

  .image-container .image.is-opened .back {
    animation: zoom-out 0.6s 0.3s;
    animation-fill-mode: forwards;
  }

  .react-card-flip-container {
    width: 100%;
    height: 100%;
  }

  .control-button {
    appearance: none;
    border: 0;
    padding: 1vw 6vw;
    color: #fff;
    font-size: 5vw;
    text-transform: uppercase;
    background-color: #e80909;
    border-radius: 5px;
    cursor: pointer;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  body:after {
    display: none;
  }
  header {
    text-align: center;
    color: #373636;
    display: flex;
    justify-content: flex-start;
    padding: 0;
  }

  header h2 {
    font-size: 3.2vw;
    font-weight: 400;
  }

  header p {
    font-size: 2.1vw;
  }

  header .logo-wrapper {
    width: 33vw;
    max-width: 100%;
  }

  header .logo-wrapper .logo {
    padding: 2vw 3vw;
    max-width: 240px;
  }

  header .text-wrapper {
    width: 55vw;
    padding: 3vh 3vh 4vh;
  }

  footer {
    margin-left: 33vw;
    margin-right: 12vw;
    padding: 0.5vw;
    font-size: 2vw;
    color: #000;
    text-align: center;
  }

  .image-container {
    background-color: rgba(255, 255, 255, 0.5);

    margin-left: 33vw;
    margin-right: 12vw;
    position: relative;
  }

  .holder {
    padding: 56.25% 0 0 0;
  }

  .image-container .video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .image-container .video-hidden {
    opacity: 0.2;
  }

  .image-container .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .image-container .image {
    flex: 0 0 15.6%;
    height: 32%;
    overflow: hidden;
  }

  .image-container .image .front {
    cursor: pointer;
  }

  .image-container .blocked .front {
    cursor: default;
  }

  .image-container .image .back {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(0, 0);
  }

  .image-container .image .back img {
    width: 90%;
  }

  .control-button {
    appearance: none;
    border: 0;
    padding: 0.6vw 3vw;
    color: #fff;
    font-size: 1.6vw;
    text-transform: uppercase;
    background-color: #e80909;
    border-radius: 5px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body {
    background: url('./assets/background@2x.png') no-repeat top left;
    background-size: cover;
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
